import { CBadge } from "@coreui/react";
import L, { LatLng, LatLngTuple } from "leaflet";
import { useEffect, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { MarkerIcon } from "../../../assets/icons/custom";
import {
  getMachineStatusLabel,
  MACHINE_STATUS_ACTIVE,
} from "../../../models/machine";
import { BoundProps, MapMachine } from "../models/mapModels";
const convertToReadableDate = (value: string | undefined) => {
  if (value === undefined) {
    return "-----";
  }
  const date = new Date(value);
  return date.toLocaleString();
};
const calculateFitBounds = (machines: MapMachine[], map: L.Map) => {
  let positions: LatLngTuple[] = [];
  if (machines.length > 0) {
    for (const mapMachine of machines) {
      if (
        mapMachine.measurement?.latitude &&
        mapMachine.measurement?.longitude &&
        mapMachine.visible
      ) {
        let position = [
          mapMachine.measurement?.latitude,
          mapMachine.measurement?.longitude,
        ] as LatLngTuple;
        positions.push(position);
      }
    }
    if (positions.length > 0) {
      map.fitBounds(positions);
    } else {
      let defaultPosition = [-25.297848, -57.604773] as LatLngTuple;
      map.fitBounds([defaultPosition]);
      map.setZoom(12);
    }
  }
};
export const SetMapFitBounds: React.FC<BoundProps> = (props: BoundProps) => {
  const machines = props.mapMachines;
  const map = useMap();

  const [selectedMapMachine, setSelectedMapMachine] =
    useState<MapMachine | null>();
  const handleClick = (e: L.LeafletMouseEvent, mapMachine: MapMachine) => {
    map.flyTo(e.latlng, map.getZoom());
    setSelectedMapMachine(mapMachine);
  };

  useEffect(() => {
    map.on("click", () => {
      setSelectedMapMachine(null);
    });
    return () => {
      map.off("click");
    };
  }, [map]);

  useEffect(() => {
    if (selectedMapMachine === null) {
      calculateFitBounds(machines, map);
    } else {
      if (!selectedMapMachine?.visible) {
        // if selectedmachine is not visible from tree
        setSelectedMapMachine(null);
        calculateFitBounds(machines, map);
      } else {
        // if selectedMapMachine position if change fly to new position
        if (
          selectedMapMachine.measurement?.latitude &&
          selectedMapMachine.measurement?.longitude
        ) {
          map.flyTo([
            selectedMapMachine.measurement?.latitude,
            selectedMapMachine.measurement?.longitude,
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);
  return (
    <>
      {
        // eslint-disable-next-line array-callback-return
        machines.map((mapMachine, i) => {
          if (
            mapMachine.measurement?.latitude &&
            mapMachine.measurement?.longitude &&
            mapMachine.visible
          ) {
            let position = new LatLng(
              mapMachine.measurement?.latitude,
              mapMachine.measurement?.longitude
            );
            let iconMap = L.divIcon({
              className: "div-icon",
              html: `
                <div class="map-icon-container">
                  <img src="${MarkerIcon}"/>
                  <span>${mapMachine.machine.name}</span>
                </div>
              `,
            });
            return (
              <Marker
                key={i}
                icon={iconMap}
                position={position}
                eventHandlers={{
                  click: (e) => {
                    handleClick(e, mapMachine);
                  },
                }}
              >
                <Popup
                  className="popup-map"
                  closeButton={false}
                  closeOnClick={true}
                >
                  <div>
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="text-center">ID Máquina</th>
                          <td className="text-center">
                            {mapMachine.measurement.machineId}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center">Ingresos</th>
                          <td className="text-center">
                            {mapMachine.measurement.cashInput}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center">Egresos</th>
                          <td className="text-center">
                            {mapMachine.measurement.cashOutput}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center">Ganancia</th>
                          <td className="text-center">
                            {mapMachine.measurement.profit}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center">Ultima Act.</th>
                          <td className="text-center">
                            {convertToReadableDate(
                              mapMachine.measurement.timestamp
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-center">Estado</th>
                          <td className="text-center">
                            <CBadge
                              style={{ fontSize: "1.1em" }}
                              size={100}
                              color={
                                mapMachine.machine.status ===
                                MACHINE_STATUS_ACTIVE
                                  ? "success"
                                  : "error"
                              }
                            >
                              {getMachineStatusLabel(mapMachine.machine.status)}
                            </CBadge>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Popup>
              </Marker>
            );
          }
        })
      }
    </>
  );
};
