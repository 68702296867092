import { getAbsoluteUrl } from "./urls";
import { fetchAuthenticated } from "./utils";
import { FAILED } from "../utils/constants/tags";

import { PasswordChangeData } from "../models/user";
import Errors from "../models/errors";

export interface ChangePasswordRequestStatus {
  status: string;
  detail?: string;
  errors?: Errors;
}

export const changeUserPassword = async (
  passwordData: PasswordChangeData
): Promise<ChangePasswordRequestStatus> => {
  const url = getAbsoluteUrl(`/auth/change_password/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(passwordData),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: ChangePasswordRequestStatus = await response.json();

  return responseData;
};
