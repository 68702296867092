import DashboardPage from "./dashboard-pages/DashboardPage";
import MachineCrudPage from "./dashboard-pages/MachineCrudPage";
import GroupCrudPage from "./dashboard-pages/GroupCrudPage";
import UserCrudPage from "./dashboard-pages/UserCrudPage";
import MachineDetailPage from "./dashboard-pages/MachineDetailPage";
import MapsPage from "./dashboard-pages/MapsPage";
import Unauthorized from "./pages/Error401";
import ReportPage from "./dashboard-pages/ReportPage";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
  superUserRequired?: boolean | undefined;
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
    superUserRequired: false,
  },
  machines: {
    path: "/machines/",
    name: "Máquinas",
    exact: true,
    component: MachineCrudPage,
    superUserRequired: false,
  },
  machineDetail: {
    path: "/machine/:id/",
    name: "Detalle de Máquina",
    exact: true,
    component: MachineDetailPage,
    superUserRequired: false,
  },
  groups: {
    path: "/groups/",
    name: "Grupos",
    exact: true,
    component: GroupCrudPage,
    superUserRequired: true,
  },
  users: {
    path: "/users/",
    name: "Usuarios",
    exact: true,
    component: UserCrudPage,
    superUserRequired: true,
  },
  maps: {
    path: "/maps/",
    name: "Mapa",
    exact: true,
    component: MapsPage,
    superUserRequired: false,
  },
  reports: {
    path: "/reports/",
    name: "Reportes",
    exact: true,
    component: ReportPage,
    superUserRequired: false,
  },
  unauthorized: {
    path: "/unauthorized/",
    name: "No esta autorizado",
    exact: true,
    component: Unauthorized,
    superUserRequired: false,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
