import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { updateMachine } from "../../api/machine";
import Machine, {
  newMachine,
  MACHINE_STATUS_ACTIVE,
  MACHINE_STATUS_INACTIVE,
} from "../../models/machine";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

interface MachineToggleStatusModalProps {
  show: boolean;
  machine?: Machine;
  currentStatus: string;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const MachineToggleStatusModal: React.FC<MachineToggleStatusModalProps> = ({
  show,
  machine,
  currentStatus,
  onCancel,
  onSuccess,
}) => {
  var machineChangedStatus: Machine;
  if (machine) {
    machineChangedStatus = {
      ...machine,
      status:
        machine.status === MACHINE_STATUS_ACTIVE
          ? MACHINE_STATUS_INACTIVE
          : MACHINE_STATUS_ACTIVE,
    };
  } else {
    machineChangedStatus = newMachine();
  }

  const [submitting, setSubmitting] = useState(false);

  const action =
    currentStatus === MACHINE_STATUS_ACTIVE ? "desactivar" : "activar";

  const message = machine
    ? `Está seguro de que quiere ${action} la máquina ${machine.name}`
    : "";

  const onToggleStatusClick = async () => {
    if (machine !== undefined) {
      setSubmitting(true);
      const machineStatus = await updateMachine(machineChangedStatus);

      if (machineStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (machineStatus.detail !== undefined) {
          message = machineStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Cambiar estado</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting}
          onClick={onToggleStatusClick}
          color="primary"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Cambiando estado..." : "Cambiar estado"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default MachineToggleStatusModal;
