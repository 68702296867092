import { PureComponent } from "react";
import { jsPanel, JsPanel } from "jspanel4";
import React from "react";
import ReactDOM from "react-dom";

interface RJsPanelPortalProps {
  rootNode: HTMLElement;
}
class RJsPanelPortal extends React.Component<RJsPanelPortalProps> {
  el: HTMLDivElement;
  constructor(props: RJsPanelPortalProps) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    this.props.rootNode.appendChild(this.el);
    // console.log(`Portal mounted, rootNode: ${this.props.rootNode.id}`);
  }

  componentWillUnmount() {
    this.props.rootNode.removeChild(this.el);
    // console.log(`Portal unmounted, rootNode: ${this.props.rootNode.id}`);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

interface JsPanelProps {
  position?: string;
  contentSize?: string;
  headerTitle?: string;
  theme?: string;
  closeIcon?: string;
  maximizeIcon?: string;
  children?: React.ReactNode;
}

class RJsPanelComponent extends PureComponent<JsPanelProps> {
  private panel?: JsPanel;
  UNSAFE_componentWillMount() {
    this.setUp();
  }

  setUp() {
    if (this.panel) {
      return;
    }
    jsPanel.ziBase = 9000;
    this.panel = jsPanel.create({
      position: this.props.position,
      contentSize: this.props.contentSize,
      headerTitle: this.props.headerTitle,
      theme: this.props.theme,
      headerControls: {
        close: this.props.closeIcon,
        maximize: this.props.maximizeIcon,
      },
      content: (panel: JsPanel) => {
        const div = document.createElement("div");
        const newId = `${panel.id}-node`;
        div.id = newId;
        panel.content?.append(div);
      },
    });
  }

  componentDidUpdate(prevProps: JsPanelProps) {
    if (!this.panel) {
      return;
    }
    if (this.props.headerTitle !== prevProps.headerTitle) {
      this.panel.setHeaderTitle(this.props.headerTitle);
    }
  }

  componentWillUnmount() {
    if (this.panel) {
      this.panel.close();
    }
  }

  render() {
    if (this.panel !== undefined) {
      const jsPanel = this.panel;
      const node = document.getElementById(`${jsPanel.id}-node`);
      if (node !== null) {
        return (
          <RJsPanelPortal rootNode={node}>{this.props.children}</RJsPanelPortal>
        );
      }
    }
    return <></>;
  }
}

export default RJsPanelComponent;
