import { FunctionComponent } from "react";

type WarningSignErrorProps = {
  errors: string[];
};

export const WarningSignError: FunctionComponent<WarningSignErrorProps> = ({
  errors,
}) => {
  if (errors.length > 0) {
    return (
      <span className="text-danger">
        <i className="fa fa-exclamation-triangle fa-lg"></i>
      </span>
    );
  } else {
    return <></>;
  }
};
