import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  createMachine,
  MachineRequestStatus,
  updateMachine,
} from "../../api/machine";
import Machine, { newMachine } from "../../models/machine";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import MachineMapModal from "./MachineMapModal";

interface MachineFormProps {
  initialMachine?: Machine;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const MachineForm: React.FC<MachineFormProps> = ({
  initialMachine,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingMachine, setEditingMachine] = useState<Machine>(
    initialMachine ? initialMachine : newMachine()
  );
  const [showMachineMapModal, setShowMachineMapModal] =
    useState<boolean>(false);

  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );

  const [submitting, setSubmitting] = useState(false);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingMachine({
      ...editingMachine,
      name: e.target.value,
    });
  };

  const onDeviceIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingMachine({
      ...editingMachine,
      deviceIdentifier: e.target.value,
    });
  };

  const openMapModal = () => {
    setShowMachineMapModal(true);
  };
  const onMachineMapModalClose = () => {
    setShowMachineMapModal(false);
  };
  const onSave = async () => {
    setSubmitting(true);
    let requestPromise: Promise<MachineRequestStatus>;
    if (editingMachine.id === 0) {
      requestPromise = createMachine(editingMachine);
    } else {
      requestPromise = updateMachine(editingMachine);
    }

    const machineStatus = await requestPromise;

    if (machineStatus.status !== SUCCESS) {
      if (machineStatus.errors !== undefined) {
        setErrors(machineStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (machineStatus.detail !== undefined) {
        message = machineStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingMachine(newMachine());
  };

  useEffect(() => {
    setEditingMachine(initialMachine ? initialMachine : newMachine());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMachine]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingMachine.name}
                onChange={onNameChange}
                placeholder="Ej: Nombre de la máquina"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Identificador</CLabel>
            </CCol>
            <CCol>
              <CInput
                readOnly={true}
                type="text"
                value={
                  editingMachine.deviceIdentifier !== undefined &&
                  editingMachine.deviceIdentifier !== null
                    ? editingMachine.deviceIdentifier
                    : ""
                }
                onChange={onDeviceIdentifierChange}
                placeholder="Ej: Identificador unico de maquina"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("deviceIdentifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Ubicación Defecto</CLabel>
            </CCol>
            <CCol>
              <CInput
                readOnly={true}
                type="text"
                value={
                  editingMachine.defaultLatitude !== undefined &&
                  editingMachine.defaultLatitude !== null
                    ? editingMachine.defaultLatitude
                    : ""
                }
                //onChange={onLatitudeChange}
                placeholder="Ej: Latitud"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("latitude", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol>
              <CInput
                readOnly={true}
                type="text"
                value={
                  editingMachine.defaultLongitude !== undefined &&
                  editingMachine.defaultLongitude !== null
                    ? editingMachine.defaultLongitude
                    : ""
                }
                //onChange={onLongitudeChange}
                placeholder="Ej: Longitud"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("longitude", errors) as string[]}
              ></FieldErrors>
            </CCol>
            <CCol md={2}>
              <CButton type="button" color="success" onClick={openMapModal}>
                Ver En Mapa
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
      <MachineMapModal
        show={showMachineMapModal}
        onClose={onMachineMapModalClose}
        latitude={editingMachine.defaultLatitude}
        longitude={editingMachine.defaultLongitude}
        onSelect={(latitude: number, longitude: number) => {
          setEditingMachine({
            ...editingMachine,
            defaultLatitude: latitude,
            defaultLongitude: longitude,
          });
        }}
      />
    </>
  );
};

export default MachineForm;
