import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavItem,
} from "@coreui/react";

//import CIcon from '@coreui/icons-react';

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );
  const isSuperUser = useSelector((state: RootState) => state.user.isSuperUser);

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("maps")}
          to={getRoutePath("maps")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("machines")}
          to={getRoutePath("machines")}
          icon={<i className="fa fa-gamepad mr-4"></i>}
        ></CSidebarNavItem>
        <CSidebarNavItem
          name={getRouteName("reports")}
          to={getRoutePath("reports")}
          icon={<i className="fa fa-map mr-4"></i>}
        ></CSidebarNavItem>
        {isSuperUser ? (
          <>
            <CSidebarNavItem
              name={getRouteName("groups")}
              to={getRoutePath("groups")}
              icon={<i className="fa fa-object-group mr-4"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("users")}
              to={getRoutePath("users")}
              icon={<i className="fa fa-users mr-4"></i>}
            ></CSidebarNavItem>
          </>
        ) : (
          <></>
        )}
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
