import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useState } from "react";
import Errors from "../../models/errors";
import { newPasswordChangeData, PasswordChangeData } from "../../models/user";
import ChangePasswordForm from "./ChangePasswordForm";
interface UserUpdateModalProps {
  show: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ChangePasswordModal: React.FC<UserUpdateModalProps> = ({
  show,
  onCancel,
  onSuccess,
}) => {
  const [errors, setErrors] = useState<Errors>({});
  const [data, setData] = useState<PasswordChangeData>(newPasswordChangeData());

  const modalTitle = "Cambiar contraseña";

  const onClose = () => {
    setErrors({});
    setData(newPasswordChangeData());
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <ChangePasswordForm
            initialData={data}
            initialErrors={errors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          ></ChangePasswordForm>
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ChangePasswordModal;
