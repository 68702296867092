import { getAbsoluteUrl } from "./urls";
import { fetchAuthenticated } from "./utils";
import { ERROR, FAILED, SUCCESS, UNAUTHORIZED } from "../utils/constants/tags";

import Machine from "../models/machine";
import Errors from "../models/errors";
import { HTTP_NO_CONTENT } from "../utils/constants/http";

export interface MachinesRequestStatus {
  status: string;
  detail?: string;
  data?: {
    items: Machine[];
    count: number;
  };
}

export const getMachines = async (
  limit: number | undefined = undefined,
  offset: number | undefined = undefined,
  search: string | undefined = undefined,
  groupId: number | undefined = undefined,
  status: string | undefined = undefined
) => {
  const params = new URLSearchParams();
  if (limit !== undefined) {
    params.set("limit", limit.toString());
  }
  if (offset !== undefined) {
    params.set("offset", offset.toString());
  }

  if (search !== undefined) {
    params.set("search", search);
  }

  if (groupId !== undefined) {
    params.set("group_id", groupId.toString());
  }
  if (status !== undefined) {
    params.set("status", status);
  }

  const url = getAbsoluteUrl("/machines/?") + params.toString();

  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const responseData: MachinesRequestStatus = await response.json();

  return responseData;
};

export interface MachineRequestStatus {
  status: string;
  detail?: string;
  data?: Machine;
  errors?: Errors;
}

export const createMachine = async (
  machine: Machine
): Promise<MachineRequestStatus> => {
  const url = getAbsoluteUrl("/machines/create/");
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(machine),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: MachineRequestStatus = await response.json();

  return responseData;
};

export const getMachine = async (id: number): Promise<MachineRequestStatus> => {
  const url = getAbsoluteUrl(`/machines/${id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: MachineRequestStatus = await response.json();
  if (response.status === 401) {
    responseData.status = UNAUTHORIZED;
  }
  return responseData;
};

export const updateMachine = async (
  machine: Machine
): Promise<MachineRequestStatus> => {
  const url = getAbsoluteUrl(`/machines/${machine.id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(machine),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: MachineRequestStatus = await response.json();

  return responseData;
};

export const deleteMachine = async (
  machine: Machine
): Promise<MachineRequestStatus> => {
  const url = getAbsoluteUrl(`/machines/${machine.id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(machine),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  if (response.status === HTTP_NO_CONTENT) {
    return { status: SUCCESS };
  }

  return {
    status: ERROR,
    detail: "Hubo un error eliminando la máquina. Intente de vuelta",
  };
};

export const generateMachinePassword = async (
  machine: Machine
): Promise<MachineRequestStatus> => {
  const url = getAbsoluteUrl(`/machines/${machine.id}/generate_password/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: MachineRequestStatus = await response.json();

  return responseData;
};
