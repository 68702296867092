import { CButton, CCol, CFormGroup, CLabel, CRow } from "@coreui/react";
import Errors, { getFieldErrors } from "../../../models/errors";
import Machine from "../../../models/machine";
import { FieldErrors } from "../../form/FieldErrors";
import GroupSetupMachineSelect from "./GroupSetupMachineSelect";

interface GroupSetupMachineItemProps {
  value: Machine;
  disabled: boolean;
  onDelete: (_: Machine) => void;
  onChange: (_: Machine | null) => void;
  errors: Errors;
}

const GroupSetupMachineItem: React.FC<GroupSetupMachineItemProps> = ({
  value,
  disabled,
  onDelete,
  onChange,
  errors,
}) => {
  const onDeleteClick = () => {
    onDelete(value);
  };
  const label = disabled ? "Máquina:" : "Asignar máquina:";

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>{label}</CLabel>
          </CCol>

          <CCol md={4}>
            <GroupSetupMachineSelect
              disabled={disabled}
              value={value}
              onChange={onChange}
            ></GroupSetupMachineSelect>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CRow className="mt-2">
        <CCol>
          {!disabled ? (
            <CButton
              className="btn btn-danger float-right"
              onClick={() => onDeleteClick()}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          ) : (
            <></>
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default GroupSetupMachineItem;
