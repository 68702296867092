import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Machine, { newMachine } from "../../models/machine";

import MachineForm from "./MachineForm";

interface MachineUpdateModalProps {
  show: boolean;
  machine?: Machine;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const MachineUpdateModal: React.FC<MachineUpdateModalProps> = ({
  show,
  machine,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialMachine, setInitialMachine] = useState<Machine>(
    machine ? machine : newMachine()
  );

  const modalTitle = machine
    ? `Editar máquina: ${machine.name}`
    : `Crear máquina nueva`;

  const onClose = () => {
    setInitialErrors({});
    setInitialMachine(newMachine());
    onCancel();
  };

  useEffect(() => {
    setInitialMachine(machine ? machine : newMachine());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machine]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <MachineForm
            initialMachine={initialMachine}
            initialErrors={initialErrors}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default MachineUpdateModal;
