import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Content, Sidebar, Header, PasswordChangeModal } from "./index";
import { successAlert } from "../utils/messages";
const Layout: React.FunctionComponent<RouteComponentProps> = () => {
  const [showModal, setShowModal] = useState(false);
  const onChangePasswordClick = () => {
    setShowModal(true);
  };
  const onCancel = () => {
    setShowModal(false);
  };

  const onSuccess = () => {
    successAlert("Contraseña cambiada con éxito!");
    setShowModal(false);
  };

  return (
    <>
      <div className="c-app c-default-layout">
        <Sidebar />
        <div className="c-wrapper">
          <Header onChangePasswordClick={onChangePasswordClick} />
          <div className="c-body">
            <Content />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <PasswordChangeModal
        show={showModal}
        onCancel={onCancel}
        onSuccess={onSuccess}
      ></PasswordChangeModal>
    </>
  );
};

export default Layout;
