import { Response } from "react-select-async-paginate";
import { getGroups } from "../../../api/group";

import Group from "../../../models/group";
import AsyncPaginateBootstrap from "../../react-select-bootstrap/AsyncPaginateBootstrap";

const RECORS_PER_SEARCH = 5;

type GroupSetupSubGroupSelectProps = {
  value: Group | null;
  disabled: boolean;
  onChange: (_: Group | null) => void;
};

const GroupSetupSubGroupSelect: React.FC<GroupSetupSubGroupSelectProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const loadGroupOptions = async (
    s: string,
    currentOpts: readonly (Group | { options: Group[]; label?: string })[]
  ): Promise<Response<Group, { options: Group[]; label?: string }, any>> => {
    let search: string | undefined = undefined;

    if (s !== "") {
      search = s;
    }

    const groupsPaginated = await getGroups(
      RECORS_PER_SEARCH,
      currentOpts.length,
      search
    );

    let newOpts: Group[] = [];
    let count = 0;

    if (groupsPaginated.data !== undefined) {
      newOpts = groupsPaginated.data.items;
      count = groupsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };

  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un grupo"}
      isDisabled={disabled}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadGroupOptions}
      getOptionLabel={(option: Group) => `${option.name}`}
      getOptionValue={(option: Group) => `${option.id}`}
      value={value}
    />
  );
};

export default GroupSetupSubGroupSelect;
