import { CButton, CCol, CFormGroup, CLabel, CRow } from "@coreui/react";
import Errors, { getFieldErrors } from "../../../models/errors";
import Group from "../../../models/group";
import { FieldErrors } from "../../form/FieldErrors";
import GroupSetupSubGroupSelect from "./GroupSetupSubGroupSelect";

interface GroupSetupSubGroupItemProps {
  value: Group;
  disabled: boolean;
  title?: string;
  onDelete: (_: Group) => void;
  onChange: (_: Group | null) => void;
  errors: Errors;
}

const GroupSetupSubGroupItem: React.FC<GroupSetupSubGroupItemProps> = ({
  value,
  disabled,
  title,
  onDelete,
  onChange,
  errors,
}) => {
  const onDeleteClick = () => {
    onDelete(value);
  };
  const objectTitle = title ? title : "Sub Grupo";
  const label = disabled ? `${objectTitle}:` : `Asignar ${objectTitle}:`;

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>{label}</CLabel>
          </CCol>

          <CCol md={4}>
            <GroupSetupSubGroupSelect
              value={value}
              disabled={disabled}
              onChange={onChange}
            ></GroupSetupSubGroupSelect>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CRow className="mt-2">
        <CCol>
          {!disabled ? (
            <CButton
              className="btn btn-danger float-right"
              onClick={() => onDeleteClick()}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          ) : (
            <></>
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default GroupSetupSubGroupItem;
