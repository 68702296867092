import { getAbsoluteUrl } from "./urls";
import { fetchAuthenticated } from "./utils";
import { FAILED } from "../utils/constants/tags";

import MachineMeasurement from "../models/machine-measurement";

export interface MachineMeasurementsRequestStatus {
  status: string;
  detail?: string;
  data?: {
    items: MachineMeasurement[];
    count: number;
  };
}

export const getMachineMeasurements = async (
  limit: number,
  offset: number,
  machineId: number | undefined = undefined,
  startTime: string | undefined = undefined,
  endTime: string | undefined = undefined
) => {
  const params = new URLSearchParams();
  params.set("limit", limit.toString());
  params.set("offset", offset.toString());

  if (machineId !== undefined) {
    params.set("machine_id", machineId.toString());
  }
  if (startTime !== undefined) {
    params.set("start_time", startTime);
  }
  if (endTime !== undefined) {
    params.set("end_time", endTime);
  }

  const url = getAbsoluteUrl("/measurements/?") + params.toString();
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const responseData: MachineMeasurementsRequestStatus = await response.json();

  return responseData;
};

export const getLastMachineMeasurements = async (
  machineId: number | undefined = undefined,
  startTime: string | undefined = undefined,
  endTime: string | undefined = undefined
) => {
  const params = new URLSearchParams();

  if (machineId !== undefined) {
    params.set("machine_id", machineId.toString());
  }
  if (startTime !== undefined) {
    params.set("start_time", startTime);
  }
  if (endTime !== undefined) {
    params.set("end_time", endTime);
  }

  const url = getAbsoluteUrl("/measurements/last/") + params.toString();
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const responseData: MachineMeasurementsRequestStatus = await response.json();

  return responseData;
};
