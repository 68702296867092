import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Loading from "../loading/Loading";

interface AppDataLoadedFirstProps {
  children: React.ReactNode;
}

const AppDataLoadedFirst: React.FC<AppDataLoadedFirstProps> = ({
  children,
}) => {
  const email = useSelector((state: RootState) => state.user.email);

  if (email === undefined || email === "") {
    return <Loading />;
  }

  return <>{children}</>;
};

export default AppDataLoadedFirst;
