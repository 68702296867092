import { getAbsoluteUrl } from "./urls";
import { fetchAuthenticated } from "./utils";
import { ERROR, FAILED, SUCCESS } from "../utils/constants/tags";

import Group from "../models/group";
import Errors from "../models/errors";
import { HTTP_NO_CONTENT } from "../utils/constants/http";

export interface GroupsRequestStatus {
  status: string;
  detail?: string;
  data?: {
    items: Group[];
    count: number;
  };
}

export const getGroups = async (
  limit: number | undefined = undefined,
  offset: number | undefined = undefined,
  search: string | undefined = undefined,
  parentGroupId: number | undefined = undefined
) => {
  const params = new URLSearchParams();
  if (limit !== undefined) {
    params.set("limit", limit.toString());
  }
  if (offset !== undefined) {
    params.set("offset", offset.toString());
  }
  if (search !== undefined) {
    params.set("search", search);
  }

  if (parentGroupId !== undefined) {
    params.set("parent_group_id", parentGroupId.toString());
  }

  const url = getAbsoluteUrl("/groups/?") + params.toString();

  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }
  const responseData: GroupsRequestStatus = await response.json();

  return responseData;
};

export interface GroupRequestStatus {
  status: string;
  detail?: string;
  data?: Group;
  errors?: Errors;
}

export const createGroup = async (
  group: Group
): Promise<GroupRequestStatus> => {
  const url = getAbsoluteUrl("/groups/create/");
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: JSON.stringify(group),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: GroupRequestStatus = await response.json();

  return responseData;
};

export const getGroup = async (id: number): Promise<GroupRequestStatus> => {
  const url = getAbsoluteUrl(`/groups/${id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: GroupRequestStatus = await response.json();

  return responseData;
};

export const updateGroup = async (
  group: Group
): Promise<GroupRequestStatus> => {
  const url = getAbsoluteUrl(`/groups/${group.id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(group),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  const responseData: GroupRequestStatus = await response.json();

  return responseData;
};

export const deleteGroup = async (
  group: Group
): Promise<GroupRequestStatus> => {
  const url = getAbsoluteUrl(`/groups/${group.id}/`);
  let response: Response = { ok: false } as Response;
  try {
    response = await fetchAuthenticated(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(group),
    });
  } catch (error) {
    return {
      status: FAILED,
      detail: "Server not Found",
    };
  }

  if (response.status === HTTP_NO_CONTENT) {
    return { status: SUCCESS };
  }

  return {
    status: ERROR,
    detail: "Hubo un error eliminando la máquina. Intente de vuelta",
  };
};
