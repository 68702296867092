import {
  CButton,
  CButtonGroup,
  CContainer,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import { Icon, LatLng } from "leaflet";

interface MachineMapModalProps {
  show: boolean;
  latitude?: number;
  longitude?: number;
  onClose: () => void | Promise<void>;
  onSelect: (latitude: number, longitude: number) => void;
}

const MachineMapModal: React.FC<MachineMapModalProps> = ({
  show,
  latitude,
  longitude,
  onClose,
  onSelect,
}) => {
  const defaultCenter = new LatLng(-25.268338, -57.485236);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [position, setPosition] = useState<LatLng | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLng | null>(defaultCenter);
  const modalTitle = `Ubicacion del Equipo`;

  const onCloseModal = () => {
    setShowMap(false);
    onClose();
  };

  const resetMapModal = () => {
    setPosition(null);
    setMapCenter(defaultCenter);
  };
  const onOpenModal = () => {
    resetMapModal();
    if (latitude && longitude) {
      let pos = new LatLng(latitude, longitude);
      setMapCenter(pos);
      setPosition(pos);
    }
    setShowMap(true);
  };
  const onSave = () => {
    if (position != null) {
      onSelect(position.lat, position.lng);
    }
    setShowMap(false);
    onCloseModal();
  };

  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setPosition(e.latlng);
      },
    });

    return <></>;
  };
  return (
    <CModal show={show} onOpened={onOpenModal} onClose={onCloseModal} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {showMap === true ? (
            <MapContainer
              id="map"
              style={{ width: "100%", height: "80vh" }}
              center={mapCenter as LatLng}
              zoom={13}
              scrollWheelZoom={true}
              zoomControl={false}
            >
              <LocationMarker />
              {position != null ? (
                <Marker
                  position={position}
                  icon={
                    new Icon({
                      iconUrl: markerIconPng,
                      iconSize: [25, 41],
                      iconAnchor: [12, 41],
                    })
                  }
                ></Marker>
              ) : (
                <></>
              )}
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          ) : (
            <></>
          )}
        </CContainer>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onSave}>
              Guardar
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </CModalBody>
    </CModal>
  );
};

export default MachineMapModal;
