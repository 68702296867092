import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { generateMachinePassword } from "../../api/machine";
import Machine from "../../models/machine";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

interface MachinePasswordModalProps {
  show: boolean;
  machine?: Machine;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const MachinePasswordModal: React.FC<MachinePasswordModalProps> = ({
  show,
  machine,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState("");

  const message = machine
    ? `Se obtendrá una contraseña para la máquina ${machine.name}. `
    : "";

  const onGenerateClick = async () => {
    if (machine !== undefined) {
      setSubmitting(true);
      const machineStatus = await generateMachinePassword(machine);

      if (machineStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (machineStatus.detail !== undefined) {
          message = machineStatus.detail;
        }
        errorAlert(message);
      } else {
        if (machineStatus.data?.password !== undefined) {
          setPassword(machineStatus.data?.password);
        }
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  const onClose = () => {
    setPassword("");
    setSubmitting(false);
    onCancel();
  };

  return (
    <CModal show={show} onClosed={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Obtener contraseña de equipo</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
              <p>{password !== "" ? `Contraseña: ${password}` : <></>}</p>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting}
          onClick={onGenerateClick}
          color="primary"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Obteniendo..." : "Obtener contraseña"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default MachinePasswordModal;
