import { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import * as mqtt from "precompiled-mqtt";
import { showDashboard } from "../../redux/actions/dashboard";
import TreeView, { TreeNode, TreeViewConfig } from "../../react-jstree";
import RJsPanel from "../../react-jspanel/RJsPanelComponent";
import MachineMeasurement from "../../models/machine-measurement";
import { getMachines } from "../../api/machine";
import { getLastMachineMeasurements } from "../../api/machine-measurement";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { RootState } from "../../store";
import Loading from "../loading/Loading";
import { MapMachine } from "./models/mapModels";
import { SetMapFitBounds } from "./components/SetMapFitBounds";
import { DefaultTreeConfig } from "./init/treeView";
import MachineIcon from "./assets/icons/machine-icon-2.png";
import "./assets/css/maps.css";
import { WS_MQTT_URL } from "../../config/mqtt";

const MapsPage = () => {
  const userState = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const [treeConfig, setTreeConfig] =
    useState<TreeViewConfig>(DefaultTreeConfig);
  const [mapMachines, setMapMachines] = useState<MapMachine[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [mqttPayload, setMqttPayload] = useState<string | null>(null);

  const getMachinesFromServer = async () => {
    const machinesRequest = await getMachines(100, 0);
    if (machinesRequest.status === SUCCESS) {
      const machinesMeasurementsRequest = await getLastMachineMeasurements();

      const machines = machinesRequest.data?.items;
      if (machines === undefined) return;
      if (machinesMeasurementsRequest.status !== SUCCESS) {
        warningAlert("Error al obtener máquinas");
        return;
      }
      const machineMeasurements = machinesMeasurementsRequest.data?.items;

      if (machineMeasurements === undefined) return;

      let mapMachinesServer: MapMachine[] = [];
      for (const machine of machines) {
        const machineMeasurement = machineMeasurements.find(
          (value: MachineMeasurement) => {
            return value.machineId === machine.id;
          }
        );
        let currentMapMachine = {
          machine: machine,
          measurement: machineMeasurement,
          visible: machineMeasurement !== undefined,
          disabled: !(machineMeasurement !== undefined),
        };
        mapMachinesServer.push(currentMapMachine);
      }
      configTreeView(mapMachinesServer);
      setMapMachines(mapMachinesServer);
    }
  };
  const configTreeView = (mapMachines: MapMachine[]) => {
    let treeData: TreeNode[] = [];

    for (const machine of mapMachines) {
      let treeNode: TreeNode = {
        id: `${machine.machine.id}`,
        text: machine.machine.name,
        children: [],
        state: {
          selected: machine.visible,
          disabled: machine.disabled,
        },
        icon: MachineIcon,
      };
      treeData.push(treeNode);
    }
    let parent = [
      {
        id: "parent",
        text: "Tragamonedas",
        children: treeData,
        state: {
          opened: true,
        },
      },
    ];
    let newConfig = {
      ...DefaultTreeConfig,
      core: { ...DefaultTreeConfig.core, data: parent },
    };
    setTreeConfig(newConfig);
  };

  useEffect(() => {
    dispatch(showDashboard(false));
    getMachinesFromServer();
    const clientOpts: mqtt.IClientOptions = {
      username: userState.email,
      password: `jwt:${userState.accessToken}`,
    };
    const brokerUrl = WS_MQTT_URL;
    let client: mqtt.MqttClient = mqtt.connect(brokerUrl, clientOpts);
    client.on("connect", () => {
      client.subscribe("/measurements/+/", (err) => {
        if (err !== null) {
          console.log(err);
        }
      });
    });
    client.on("message", (topic, message) => {
      setMqttPayload(message.toString());
    });
    return () => {
      client.end();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (loading) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMachines]);

  useEffect(() => {
    if (mqttPayload === null) return;
    //* only one measurement form a device
    const newMeasurement: MachineMeasurement = JSON.parse(mqttPayload);
    let mapMachinesCopy = [...mapMachines];
    let searchMapMachineIndex = mapMachinesCopy.findIndex(
      (_mapMachine) =>
        _mapMachine.machine.deviceIdentifier === newMeasurement.deviceIdentifier
    );
    if (searchMapMachineIndex !== -1) {
      mapMachinesCopy[searchMapMachineIndex].measurement = newMeasurement;
      if (searchMapMachineIndex) {
        if (mapMachinesCopy[searchMapMachineIndex].disabled) {
          mapMachinesCopy[searchMapMachineIndex].disabled = false;
          mapMachinesCopy[searchMapMachineIndex].visible = false;
          configTreeView([...mapMachinesCopy]);
        }
      }
      setMapMachines([...mapMachinesCopy]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqttPayload]);
  const onChangeTree = async (e: any, data: any) => {
    let selectedMachinesIDs: string[] = data.selected;
    let mapMachinesCopy = [...mapMachines];
    for (let mapMachine of mapMachinesCopy) {
      const foundedMachineID = selectedMachinesIDs.findIndex(
        (id) => Number(id) === mapMachine.machine.id
      );
      mapMachine.visible = foundedMachineID !== -1;
    }
    if (data.action === "select_node" || data.action === "deselect_node") {
      setMapMachines([...mapMachinesCopy]);
    }
  };

  return loading === true ? (
    <Loading />
  ) : (
    <>
      <MapContainer
        id="map"
        style={{ width: "100vw", height: "93vh" }}
        center={[-25.268338, -57.485236]}
        zoom={13}
        scrollWheelZoom={true}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <RJsPanel
          contentSize="250 100%"
          headerTitle="Maquinas"
          position="left-top"
          theme="primary"
          closeIcon="remove"
          maximizeIcon="remove"
        >
          <TreeView treeData={treeConfig} onChange={onChangeTree} />
        </RJsPanel>
        <SetMapFitBounds
          mapMachines={mapMachines}
          setMapMachineFocused={() => {}}
        />
      </MapContainer>
    </>
  );
};
export default MapsPage;
