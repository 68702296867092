import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLink,
  CLabel,
  CInput,
  CInputGroupText,
  CInputGroup,
  CSelect,
} from "@coreui/react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import Machine, {
  MACHINE_STATUS_CHOICES,
  MACHINE_STATUS_ACTIVE,
  MACHINE_STATUS_ACTIVE_LABEL,
  MACHINE_STATUS_INACTIVE,
  MACHINE_STATUS_INACTIVE_LABEL,
  MACHINE_STATUS_PENDING_APPROVAL,
  MACHINE_STATUS_PENDING_APPROVAL_LABEL,
} from "../../models/machine";
import { getRoutePath } from "../../routes";
import { successAlert, warningAlert } from "../utils/messages";
import MachineDeleteModal from "./MachineDeleteModal";
import MachineUpdateModal from "./MachineUpdateModal";
import MachineToggleStatusModal from "./MachineToggleStatusModal";
import MachinePasswordModal from "./MachinePasswordModal";
import { getMachines } from "../../api/machine";
import { SUCCESS } from "../../utils/constants/tags";

const ITEMS_PER_PAGE = 10;

const MachineList = () => {
  const fields = [
    { key: "name", _classes: ["text-center"], label: "Nombre" },
    {
      key: "deviceIdentifier",
      _classes: ["text-center"],
      label: "Identificador",
    },
    { key: "status", _classes: ["text-center"], label: "Estado" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];
  const isSuperUser = useSelector((state: RootState) => state.user.isSuperUser);
  const [showMachineEditModal, setShowMachineEditModal] = useState(false);
  const [showMachineDeleteModal, setShowMachineDeleteModal] = useState(false);
  const [showToggleStatusModal, setShowToggleStatusModal] = useState(false);
  const [showMachinePasswordModal, setShowMachinePasswordModal] =
    useState(false);
  const [machine, setMachine] = useState<Machine | undefined>(undefined);
  const [search, setSearch] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");

  const [loading, setLoading] = useState(true);
  const [, setFirstLoad] = useState(true);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let search = urlParams.get("search") ? urlParams.get("search") : "";
    let status = urlParams.get("status") ? urlParams.get("status") : "";

    return { search: search, status: status, page: page };
  };

  const fetchMachines = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const searchValue = urlParams.search ? urlParams.search : undefined;
    const statusValue = urlParams.status ? urlParams.status : undefined;
    const machinesStatus = await getMachines(
      limit,
      offset,
      searchValue,
      undefined,
      statusValue
    );

    if (machinesStatus.status === SUCCESS) {
      if (machinesStatus.data !== undefined) {
        const count = machinesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setMachines(machinesStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = machinesStatus.detail
        ? machinesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set("search", previousParams.search ? previousParams.search : "");
    urlParams.set("status", previousParams.status ? previousParams.status : "");
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    setShowMachineEditModal(true);
  };

  const onUpdateClick = (machine: Machine) => {
    setMachine(machine);
    setShowMachineEditModal(true);
  };

  const onDeleteClick = (machine: Machine) => {
    setMachine(machine);
    setShowMachineDeleteModal(true);
  };

  const onToggleStatusClick = (machine: Machine) => {
    setMachine(machine);
    setShowToggleStatusModal(true);
  };

  const onGenerarePasswordClick = (machine: Machine) => {
    setMachine(machine);
    setShowMachinePasswordModal(true);
  };

  const onMachineEditCancel = () => {
    setMachine(undefined);
    setShowMachineEditModal(false);
  };

  const onMachineEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowMachineEditModal(false);
    setMachines([]);
    setLoading(true);
    fetchMachines();
  };

  const onMachineDeleteCancel = () => {
    setMachine(undefined);
    setShowMachineDeleteModal(false);
  };

  const onMachineDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowMachineDeleteModal(false);
    setMachines([]);
    setLoading(true);
    fetchMachines();
  };

  const onToggleStatusCancel = () => {
    setMachine(undefined);
    setShowToggleStatusModal(false);
  };

  const onToggleStatusSuccess = () => {
    successAlert("Estado cambiado con éxito!");
    setShowToggleStatusModal(false);
    setMachines([]);
    setLoading(true);
    fetchMachines();
  };

  const onGeneratePasswordCancel = () => {
    setMachine(undefined);
    setShowMachinePasswordModal(false);
  };

  const onGeneratePasswordSuccess = () => {
    successAlert("Contraseña de dispositivo generada con éxito!");
    setMachines([]);
    setLoading(true);
    fetchMachines();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStatusFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatusFilter(e.target.value);
  };

  const onSearch = () => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("search", search);
    urlParams.set("status", statusFilter);
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  useEffect(() => {
    fetchMachines();
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("machines")) {
        return;
      }
      fetchMachines();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Todas las Máquinas</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel>Filtro de estado:</CLabel>
                  <CSelect onChange={onStatusFilterChange} value={statusFilter}>
                    <option value="">Seleccione una opción</option>
                    <option value={MACHINE_STATUS_ACTIVE}>
                      {MACHINE_STATUS_ACTIVE_LABEL}
                    </option>
                    <option value={MACHINE_STATUS_INACTIVE}>
                      {MACHINE_STATUS_INACTIVE_LABEL}
                    </option>
                    <option value={MACHINE_STATUS_PENDING_APPROVAL}>
                      {MACHINE_STATUS_PENDING_APPROVAL_LABEL}
                    </option>
                  </CSelect>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de busqueda"
                      onChange={onSearchChange}
                      value={search}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={machines}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    deviceIdentifier: (item: Machine) => {
                      const deviceIdentifier =
                        item.deviceIdentifier !== undefined &&
                        item.deviceIdentifier !== null
                          ? item.deviceIdentifier
                          : "";
                      return (
                        <td className="text-center">{deviceIdentifier}</td>
                      );
                    },
                    actions: (item: Machine) => {
                      const generatePasswordButton = (
                        <CButton
                          className="text-white"
                          color="info"
                          onClick={() => {
                            onGenerarePasswordClick(item);
                          }}
                        >
                          <i className="fa fa-key"></i>
                        </CButton>
                      );
                      const editButton = (
                        <CButton
                          className="text-white"
                          color="warning"
                          onClick={() => {
                            onUpdateClick(item);
                          }}
                        >
                          <i className="fa fa-pencil"></i>
                        </CButton>
                      );
                      const deleteButton = (
                        <CButton
                          className="text-white"
                          color="danger"
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </CButton>
                      );
                      const toggleStatusButton =
                        item.status === MACHINE_STATUS_ACTIVE ? (
                          <CButton
                            className="text-white"
                            color="secondary"
                            onClick={() => {
                              onToggleStatusClick(item);
                            }}
                          >
                            <i className="fa fa-caret-down"></i>
                          </CButton>
                        ) : (
                          <CButton
                            className="text-white"
                            color="success"
                            onClick={() => {
                              onToggleStatusClick(item);
                            }}
                          >
                            <i className="fa fa-caret-up"></i>
                          </CButton>
                        );
                      const detailButton = (
                        <CLink
                          className="btn btn-primary"
                          to={getRoutePath("machineDetail").replace(
                            ":id",
                            item.id.toString()
                          )}
                        >
                          <i className="fa fa-eye"></i>
                        </CLink>
                      );
                      return (
                        <td className="text-right">
                          <CButtonGroup>
                            {detailButton}
                            {isSuperUser ? (
                              <>
                                {generatePasswordButton}
                                {toggleStatusButton} {editButton} {deleteButton}
                              </>
                            ) : (
                              <></>
                            )}
                          </CButtonGroup>
                        </td>
                      );
                    },
                    status: (item: Machine) => {
                      const machineLabel = MACHINE_STATUS_CHOICES.get(
                        item.status
                      );
                      return <td className="text-center">{machineLabel}</td>;
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <MachineUpdateModal
        show={showMachineEditModal}
        onCancel={onMachineEditCancel}
        onSuccess={onMachineEditSuccess}
        machine={machine}
      />
      <MachineDeleteModal
        show={showMachineDeleteModal}
        onCancel={onMachineDeleteCancel}
        onSuccess={onMachineDeleteSuccess}
        machine={machine}
      />
      <MachineToggleStatusModal
        show={showToggleStatusModal}
        onCancel={onToggleStatusCancel}
        onSuccess={onToggleStatusSuccess}
        machine={machine}
        currentStatus={machine ? machine.status : MACHINE_STATUS_ACTIVE}
      />
      <MachinePasswordModal
        show={showMachinePasswordModal}
        machine={machine}
        onCancel={onGeneratePasswordCancel}
        onSuccess={onGeneratePasswordSuccess}
      />
    </>
  );
};

export default MachineList;
