import { CButton, CCol, CFormGroup, CLabel, CRow } from "@coreui/react";
import Errors, { getFieldErrors } from "../../../models/errors";
import User from "../../../models/user";
import { FieldErrors } from "../../form/FieldErrors";
import GroupSetupUserSelect from "./GroupSetupUserSelect";

interface GroupSetupUserItemProps {
  value: User;
  disabled: boolean;
  onDelete: (_: User) => void;
  onChange: (_: User | null) => void;
  errors: Errors;
}

const GroupSetupUserItem: React.FC<GroupSetupUserItemProps> = ({
  value,
  disabled,
  onDelete,
  onChange,
  errors,
}) => {
  const onDeleteClick = () => {
    onDelete(value);
  };

  const label = disabled ? "Usuario:" : "Asignar usuario:";

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>{label}</CLabel>
          </CCol>

          <CCol md={4}>
            <GroupSetupUserSelect
              value={value}
              disabled={disabled}
              onChange={onChange}
            ></GroupSetupUserSelect>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CRow className="mt-2">
        <CCol>
          {!disabled ? (
            <CButton
              className="btn btn-danger float-right"
              onClick={() => onDeleteClick()}
            >
              <i className="fa fa-trash"></i>
            </CButton>
          ) : (
            <></>
          )}
        </CCol>
      </CRow>
    </div>
  );
};

export default GroupSetupUserItem;
