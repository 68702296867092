import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CContainer, CFade } from "@coreui/react";

// routes config
import routes from "../../routes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Content = () => {
  let routeComponents: React.ReactElement[] = [];
  for (const key in routes) {
    const route = routes[key];
    routeComponents.push(
      <Route
        key={key}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <CFade>
            <route.component {...props} />
          </CFade>
        )}
      />
    );
  }
  return (
    <main className="c-main" style={{ paddingTop: "0px" }}>
      <CContainer fluid style={{ paddingLeft: "10px", paddingRight: "10px" }}>
        <Suspense fallback={loading}>
          <Switch>
            {routeComponents}
            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(Content);
