interface Group {
  id: number;
  name: string;
  description?: string;
  subGroupIds?: number[];
  userIds?: number[];
  machineIds?: number[];
  emails?: string[];
}

export const newGroup = (): Group => {
  return {
    id: 0,
    name: "",
    description: "",
    subGroupIds: [],
    userIds: [],
    machineIds: [],
    emails: [],
  };
};

export default Group;
