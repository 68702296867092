import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useEffect, useState } from "react";
import Errors from "../../models/errors";
import Group, { newGroup } from "../../models/group";
import GroupSetup from "./GroupSetup";
interface GroupUpdateModalProps {
  show: boolean;
  disabled: boolean;
  group?: Group;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const MachineUpdateModal: React.FC<GroupUpdateModalProps> = ({
  show,
  disabled,
  group,
  onCancel,
  onSuccess,
}) => {
  const [initialErrors, setInitialErrors] = useState<Errors>({});
  const [initialGroup, setInitialGroup] = useState<Group>(
    group ? group : newGroup()
  );
  const modalTitle = group
    ? `Editar Grupo: ${group.name}`
    : `Crear grupo nuevo`;

  const onClose = () => {
    setInitialErrors({});
    setInitialGroup(newGroup());
    onCancel();
  };

  useEffect(() => {
    setInitialGroup(group ? group : newGroup());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{!disabled ? modalTitle : <></>}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <GroupSetup
            initialGroup={initialGroup}
            initialErrors={initialErrors}
            disabled={disabled}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default MachineUpdateModal;
