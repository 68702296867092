interface Errors {
  [key: string]: string[] | Errors | Errors[];
}

export const getFieldErrors = (
  fieldName: string,
  errors?: Errors
): string[] | Errors | Errors[] => {
  if (errors) {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  }

  return [];
};

export default Errors;
