import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CInputGroup,
  CSwitch,
  CCardTitle,
} from "@coreui/react";
import { useEffect, useState, useCallback } from "react";
import { Spinner } from "react-bootstrap";
import { createUser, UserRequestStatus, updateUser } from "../../api/user";
import { getGroup } from "../../api/group";
import User, { newUser } from "../../models/user";
import Group, { newGroup } from "../../models/group";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import GroupSetupSubGroupItem from "../groups/group-select-items/GroupSetupSubGroupItem";

interface UserFormProps {
  initialUser?: User;
  initialErrors?: Errors;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const UserForm: React.FC<UserFormProps> = ({
  initialUser,
  initialErrors,
  onCancel,
  onSuccess,
}) => {
  const [editingUser, setEditingUser] = useState<User>(
    initialUser ? initialUser : newUser()
  );
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [submitting, setSubmitting] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const fetchGroupOptions = async () => {
    if (initialUser !== undefined) {
      if (initialUser.groupIds !== undefined) {
        var asyncFunctions = [];
        for (let i = 0; i < initialUser.groupIds?.length; i++) {
          asyncFunctions.push(getGroup(initialUser.groupIds[i]));
        }
        const results = await Promise.all(asyncFunctions);
        var resultGroups: Group[] = [];
        for (let i = 0; i < results.length; i++) {
          if (results[i].status === SUCCESS && results[i].data !== undefined) {
            resultGroups.push(results[i].data!);
          }
        }
        setSelectedGroups(resultGroups);
      }
    }
  };

  const onGroupAddItemClick = () => {
    const newItems = [...selectedGroups, newGroup()];
    setSelectedGroups(newItems);
    if (editingUser !== undefined) {
      const newRelatedIds = editingUser.groupIds
        ? [...editingUser.groupIds, newGroup().id]
        : [newGroup().id];
      setEditingUser({ ...editingUser, groupIds: newRelatedIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onGroupItemDeleteClick = useCallback(
    (clickedIndex: number, clickedGroup: Group) => {
      let array = selectedGroups;
      if (clickedIndex !== -1) {
        array.splice(clickedIndex, 1);
        setSelectedGroups([...array]);
      }
      if (editingUser !== undefined) {
        let arrayIds = editingUser.groupIds;
        if (clickedIndex !== -1 && arrayIds !== undefined) {
          arrayIds.splice(clickedIndex, 1);
          setEditingUser({ ...editingUser, groupIds: arrayIds });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroups]
  );

  const onGroupItemChange = useCallback(
    (index: number, newchosenGroup: Group | null) => {
      const newSelectedGroups = selectedGroups.map((item, ix) => {
        if (ix === index) {
          if (newchosenGroup !== null) {
            return newchosenGroup;
          } else {
            return newGroup();
          }
        } else {
          return item;
        }
      });
      setSelectedGroups(newSelectedGroups);
      if (editingUser !== undefined) {
        if (editingUser.groupIds !== undefined) {
          const newSelectedGroupIds = editingUser.groupIds.map((id, ix) => {
            if (ix === index) {
              if (newchosenGroup !== null) {
                return newchosenGroup.id;
              } else {
                return newGroup().id;
              }
            }
            return id;
          });
          setEditingUser({
            ...editingUser,
            groupIds: newSelectedGroupIds,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedGroups]
  );

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      firstName: e.target.value,
    });
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      lastName: e.target.value,
    });
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      username: e.target.value,
      email: e.target.value,
    });
  };

  const onIsSuperUserChange = () => {
    setEditingUser({
      ...editingUser,
      isSuperUser: !editingUser.isSuperUser,
    });
  };

  const onPassword1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password1: e.target.value,
    });
  };

  const onPassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingUser({
      ...editingUser,
      password2: e.target.value,
    });
  };

  const onPassword1ShowClick = () => {
    setShowPassword1(!showPassword1);
  };

  const onPassword2ShowClick = () => {
    setShowPassword2(!showPassword2);
  };

  const onSave = async () => {
    setSubmitting(true);
    let toSendUser = {
      ...editingUser,
      groupIds: editingUser?.groupIds?.filter((entry) => entry > 0),
    };
    let requestPromise: Promise<UserRequestStatus>;
    if (editingUser.id === 0) {
      requestPromise = createUser(toSendUser);
    } else {
      requestPromise = updateUser(toSendUser);
    }

    const userStatus = await requestPromise;

    if (userStatus.status !== SUCCESS) {
      if (userStatus.errors !== undefined) {
        setErrors(userStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (userStatus.detail !== undefined) {
        message = userStatus.detail;
      }
      errorAlert(message);
    } else {
      setErrors({});
      clearForm();
      setSelectedGroups([]);
      onSuccess();
    }
    setSubmitting(false);
  };

  const onClose = () => {
    clearForm();
    setSelectedGroups([]);
    onCancel();
  };

  const clearForm = () => {
    setEditingUser(newUser());
  };

  useEffect(() => {
    fetchGroupOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser]);

  useEffect(() => {
    setEditingUser(initialUser ? initialUser : newUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUser]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingUser.firstName}
                onChange={onFirstNameChange}
                placeholder="Ej: Nombre"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("firstName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Apellido</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingUser.lastName}
                onChange={onLastNameChange}
                placeholder="Ej: Apellido"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("lastName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Correo electrónico</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={editingUser.email}
                onChange={onEmailChange}
                placeholder="Ej: Correo electrónico"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
              <FieldErrors
                errors={getFieldErrors("username", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Usuario Admin:</CLabel>
            </CCol>
            <CCol>
              <CSwitch
                color="primary"
                shape="pill"
                checked={editingUser.isSuperUser}
                onChange={onIsSuperUserChange}
              ></CSwitch>
              <FieldErrors
                errors={getFieldErrors("isSuperUser", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {initialUser?.id !== 0 ? (
          <></>
        ) : (
          <>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Contraseña</CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <CInput
                      type={showPassword1 ? "text" : "password"}
                      value={editingUser.password1}
                      onChange={onPassword1Change}
                      placeholder="Ej: Contraseña"
                    ></CInput>
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onPassword1ShowClick}
                    >
                      {showPassword1 ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </CButton>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("password1", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Repita la Contraseña</CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <CInput
                      type={showPassword2 ? "text" : "password"}
                      value={editingUser.password2}
                      onChange={onPassword2Change}
                      placeholder="Ej: Repita la Contraseña"
                    ></CInput>
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onPassword2ShowClick}
                    >
                      {showPassword2 ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </CButton>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("password2", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          </>
        )}

        <CRow>
          <CCol>
            <CCardTitle>Asignar Grupos</CCardTitle>
          </CCol>
        </CRow>

        {selectedGroups ? (
          selectedGroups.map((item: Group, ix) => (
            <CRow key={ix}>
              <CCol>
                <GroupSetupSubGroupItem
                  title="Grupo"
                  value={item}
                  disabled={
                    editingUser.isSuperUser ? editingUser.isSuperUser : false
                  }
                  onChange={(item: Group | null) => onGroupItemChange(ix, item)}
                  onDelete={(item: Group) => onGroupItemDeleteClick(ix, item)}
                  errors={errors}
                ></GroupSetupSubGroupItem>
              </CCol>
            </CRow>
          ))
        ) : (
          <></>
        )}
        {!editingUser.isSuperUser ? (
          <CRow>
            <CCol>
              <CButton
                className="btn btn-success"
                onClick={onGroupAddItemClick}
              >
                Agregar Ítem
              </CButton>
            </CCol>
          </CRow>
        ) : (
          <></>
        )}

        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Atras
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default UserForm;
