import MachineCrud from "../components/machine/MachineCrud";

const MachineCrudPage = () => {
  return (
    <>
      <MachineCrud />
    </>
  );
};

export default MachineCrudPage;
