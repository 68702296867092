import { Route } from "react-router-dom";

import { useSelector } from "react-redux";

import { RootState } from "../store";
import Error403 from "../pages/Error403";
const SuperUserRequiredRoute = ({ ...props }) => {
  const isSuperUser = useSelector((state: RootState) => state.user.isSuperUser);

  if (!isSuperUser) {
    return <Error403 />;
  }
  return <Route {...props}></Route>;
};

export default SuperUserRequiredRoute;
