import MachineDetail from "../components/machine/MachineDetail";

const MachineDetailPage = () => {
  return (
    <>
      <MachineDetail />
    </>
  );
};

export default MachineDetailPage;
