import UserCrud from "../components/user/UserCrud";

const UserCrudPage = () => {
  return (
    <>
      <UserCrud />
    </>
  );
};

export default UserCrudPage;
