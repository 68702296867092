import GroupCrud from "../components/groups/GroupCrud";

const GroupCrudPage = () => {
  return (
    <>
      <GroupCrud />
    </>
  );
};

export default GroupCrudPage;
