export const DefaultTreeConfig = {
  plugins: ["checkbox"],
  core: {
    data: [],
    multiple: true,
  },
  checkbox: {
    tie_selection: true,
    cascade: "up+down+undetermined",
    whole_node: true,
    keep_selected_style: false,
  },
};
