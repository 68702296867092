import { Response } from "react-select-async-paginate";
import { getUsers } from "../../../api/user";

import User from "../../../models/user";
import AsyncPaginateBootstrap from "../../react-select-bootstrap/AsyncPaginateBootstrap";

const RECORS_PER_SEARCH = 5;

type GroupSetupUserSelectProps = {
  value: User | null;
  disabled: boolean;
  onChange: (_: User | null) => void;
};

const GroupSetupUserSelect: React.FC<GroupSetupUserSelectProps> = ({
  value,
  disabled,
  onChange,
}) => {
  const loadUserOptions = async (
    s: string,
    currentOpts: readonly (User | { options: User[]; label?: string })[]
  ): Promise<Response<User, { options: User[]; label?: string }, any>> => {
    let search: string | undefined = undefined;

    if (s !== "") {
      search = s;
    }

    const usersPaginated = await getUsers(
      RECORS_PER_SEARCH,
      currentOpts.length,
      search
    );

    let newOpts: User[] = [];
    let count = 0;

    if (usersPaginated.data !== undefined) {
      newOpts = usersPaginated.data.items;
      count = usersPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };

  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Usuario"}
      isDisabled={disabled}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadUserOptions}
      getOptionLabel={(option: User) => `${option.username}`}
      getOptionValue={(option: User) => `${option.id}`}
      value={value}
    />
  );
};

export default GroupSetupUserSelect;
