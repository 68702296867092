interface Machine {
  id: number;
  name: string;
  status: string;
  password?: string;
  deviceIdentifier?: string | null;
  lastSeen?: string;
  defaultLatitude?: number;
  defaultLongitude?: number;
}

//statuses
export const MACHINE_STATUS_ACTIVE = "active";
export const MACHINE_STATUS_ACTIVE_LABEL = "Activo";
export const MACHINE_STATUS_INACTIVE = "inactive";
export const MACHINE_STATUS_INACTIVE_LABEL = "Inactivo";
export const MACHINE_STATUS_PENDING_APPROVAL = "pending_approval";
export const MACHINE_STATUS_PENDING_APPROVAL_LABEL = "Pendiente de Aprobación";

export const MACHINE_STATUS_CHOICES: Map<string, string> = new Map([
  [MACHINE_STATUS_ACTIVE, MACHINE_STATUS_ACTIVE_LABEL],
  [MACHINE_STATUS_INACTIVE, MACHINE_STATUS_INACTIVE_LABEL],
  [MACHINE_STATUS_PENDING_APPROVAL, MACHINE_STATUS_PENDING_APPROVAL_LABEL],
]);

export const getMachineStatusLabel = (status: string) => {
  return MACHINE_STATUS_CHOICES.get(status);
};
export const newMachine = (): Machine => {
  return {
    id: 0,
    name: "",
    deviceIdentifier: "",
    password: "",
    status: MACHINE_STATUS_ACTIVE,
    lastSeen: "",
  };
};

export default Machine;
