interface User {
  id: number;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password1?: string;
  password2?: string;
  isSuperUser?: boolean;
  groupIds?: number[];
}

export const newUser = (): User => {
  return {
    id: 0,
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    password1: "",
    password2: "",
    isSuperUser: false,
    groupIds: [],
  };
};

export interface PasswordChangeData {
  currentPassword?: string;
  newPassword1?: string;
  newPassword2?: string;
}

export const newPasswordChangeData = (): PasswordChangeData => {
  return {
    currentPassword: "",
    newPassword1: "",
    newPassword2: "",
  };
};

export default User;
