import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
  CCol,
  CNav,
  CContainer,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CButton,
  CInput,
  CFormGroup,
  CLabel,
} from "@coreui/react";
import { Spinner } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import { GroupRequestStatus, updateGroup, createGroup } from "../../api/group";
import Errors, { getFieldErrors } from "../../models/errors";
import Machine, { newMachine } from "../../models/machine";
import Group, { newGroup } from "../../models/group";
import User, { newUser } from "../../models/user";
import { getMachines } from "../../api/machine";
import { getUsers } from "../../api/user";
import { getGroups } from "../../api/group";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { WarningSignError } from "../form/WarningSignError";
import { errorAlert, warningAlert } from "../utils/messages";
import GroupSetupMachineItem from "./group-select-items/GroupSetupMachineItem";
import GroupSetupUserItem from "./group-select-items/GroupSetupUserItem";
import GroupSetupSubGroupItem from "./group-select-items/GroupSetupSubGroupItem";

interface GroupFormProps {
  initialGroup?: Group;
  initialErrors?: Errors;
  disabled: boolean;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const GroupSetup: React.FC<GroupFormProps> = ({
  initialGroup,
  initialErrors,
  disabled,
  onCancel,
  onSuccess,
}) => {
  const [, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>(
    initialErrors ? initialErrors : {}
  );
  const [editingGroup, setEditingGroup] = useState<Group>(
    initialGroup ? initialGroup : newGroup()
  );

  const [selectedMachines, setSelectedMachines] = useState<Machine[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [selectedSubGroups, setSelectedSubGroups] = useState<Group[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState("machines");

  const getInitialValues = async () => {
    setLoading(true);
    const machineOptionPromise = fetchMachineOptions();
    const userOptionPromise = fetchUserOptions();
    const subGroupOptionPromise = fetchSubGroupOptions();

    await machineOptionPromise;
    await userOptionPromise;
    await subGroupOptionPromise;
    setLoading(false);
  };

  const fetchMachineOptions = async () => {
    const machinesStatus = await getMachines(
      undefined,
      undefined,
      undefined,
      initialGroup?.id
    );

    if (machinesStatus.status === SUCCESS) {
      if (machinesStatus.data !== undefined) {
        setSelectedMachines(machinesStatus.data.items);
      }
    } else {
      const message = machinesStatus.detail
        ? machinesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchUserOptions = async () => {
    const usersStatus = await getUsers(
      undefined,
      undefined,
      undefined,
      initialGroup?.id
    );

    if (usersStatus.status === SUCCESS) {
      if (usersStatus.data !== undefined) {
        setSelectedUsers(usersStatus.data.items);
      }
    } else {
      const message = usersStatus.detail
        ? usersStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchSubGroupOptions = async () => {
    const subGroupsStatus = await getGroups(
      undefined,
      undefined,
      undefined,
      initialGroup?.id
    );

    if (subGroupsStatus.status === SUCCESS) {
      if (subGroupsStatus.data !== undefined) {
        setSelectedSubGroups(subGroupsStatus.data.items);
      }
    } else {
      const message = subGroupsStatus.detail
        ? subGroupsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const onNavTabClick = (tabName: string) => {
    if (activeTab !== tabName) {
      setActiveTab(tabName);
    }
  };

  const onMachineItemDeleteClick = useCallback(
    (clickedIndex: number, clickedMachine: Machine) => {
      let array = selectedMachines;
      if (clickedIndex !== -1) {
        array.splice(clickedIndex, 1);
        setSelectedMachines([...array]);
      }
      if (editingGroup !== undefined) {
        let arrayIds = editingGroup.machineIds;
        if (clickedIndex !== -1 && arrayIds !== undefined) {
          arrayIds.splice(clickedIndex, 1);
          setEditingGroup({ ...editingGroup, machineIds: arrayIds });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMachines]
  );

  const onMachineItemChange = useCallback(
    (index: number, newchosenMachine: Machine | null) => {
      const newSelectedMachines = selectedMachines.map((item, ix) => {
        if (ix === index) {
          if (newchosenMachine !== null) {
            return newchosenMachine;
          } else {
            return newMachine();
          }
        } else {
          return item;
        }
      });
      setSelectedMachines(newSelectedMachines);
      if (editingGroup !== undefined) {
        if (editingGroup.machineIds !== undefined) {
          const newSelectedMachineIds = editingGroup.machineIds.map(
            (id, ix) => {
              if (ix === index) {
                if (newchosenMachine !== null) {
                  return newchosenMachine.id;
                } else {
                  return newMachine().id;
                }
              }
              return id;
            }
          );
          setEditingGroup({
            ...editingGroup,
            machineIds: newSelectedMachineIds,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMachines]
  );

  const onMachineAddItemClick = () => {
    const newItems = [...selectedMachines, newMachine()];
    setSelectedMachines(newItems);
    if (editingGroup !== undefined) {
      const newRelatedIds = editingGroup.machineIds
        ? [...editingGroup.machineIds, newMachine().id]
        : [newMachine().id];
      setEditingGroup({ ...editingGroup, machineIds: newRelatedIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onUserItemDeleteClick = useCallback(
    (clickedIndex: number, clickedUser: User) => {
      let array = selectedUsers;
      if (clickedIndex !== -1) {
        array.splice(clickedIndex, 1);
        setSelectedUsers([...array]);
      }
      if (editingGroup !== undefined) {
        let arrayIds = editingGroup.userIds;
        if (clickedIndex !== -1 && arrayIds !== undefined) {
          arrayIds.splice(clickedIndex, 1);
          setEditingGroup({ ...editingGroup, userIds: arrayIds });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUsers]
  );

  const onUserItemChange = useCallback(
    (index: number, newChosenUser: User | null) => {
      const newSelectedUsers = selectedUsers.map((item, ix) => {
        if (ix === index) {
          if (newChosenUser !== null) {
            return newChosenUser;
          } else {
            return newUser();
          }
        } else {
          return item;
        }
      });
      setSelectedUsers(newSelectedUsers);
      if (editingGroup !== undefined) {
        if (editingGroup.userIds !== undefined) {
          const newSelectedUserIds = editingGroup.userIds.map((id, ix) => {
            if (ix === index) {
              if (newChosenUser !== null) {
                return newChosenUser.id;
              } else {
                return newUser().id;
              }
            }
            return id;
          });
          setEditingGroup({ ...editingGroup, userIds: newSelectedUserIds });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUsers]
  );

  const onUserAddItemClick = () => {
    const newItems = [...selectedUsers, newUser()];
    setSelectedUsers(newItems);
    if (editingGroup !== undefined) {
      const newRelatedIds = editingGroup.userIds
        ? [...editingGroup.userIds, newUser().id]
        : [newUser().id];
      setEditingGroup({ ...editingGroup, userIds: newRelatedIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onSubGroupItemDeleteClick = useCallback(
    (clickedIndex: number, clickedSubGroup: Group) => {
      let array = selectedSubGroups;
      if (clickedIndex !== -1) {
        array.splice(clickedIndex, 1);
        setSelectedSubGroups([...array]);
      }
      if (editingGroup !== undefined) {
        let arrayIds = editingGroup.subGroupIds;
        if (clickedIndex !== -1 && arrayIds !== undefined) {
          arrayIds.splice(clickedIndex, 1);
          setEditingGroup({ ...editingGroup, subGroupIds: arrayIds });
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSubGroups]
  );

  const onSubGroupItemChange = useCallback(
    (index: number, newchosenSubGroup: Group | null) => {
      const newSelectedSubGroups = selectedSubGroups.map((item, ix) => {
        if (ix === index) {
          if (newchosenSubGroup !== null) {
            return newchosenSubGroup;
          } else {
            return newGroup();
          }
        } else {
          return item;
        }
      });
      setSelectedSubGroups(newSelectedSubGroups);
      if (editingGroup !== undefined) {
        if (editingGroup.subGroupIds !== undefined) {
          const newSelectedSubGroupIds = editingGroup.subGroupIds.map(
            (id, ix) => {
              if (ix === index) {
                if (newchosenSubGroup !== null) {
                  return newchosenSubGroup.id;
                } else {
                  return newGroup().id;
                }
              }
              return id;
            }
          );
          setEditingGroup({
            ...editingGroup,
            subGroupIds: newSelectedSubGroupIds,
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSubGroups]
  );

  const onSubGroupAddItemClick = () => {
    const newItems = [...selectedSubGroups, newGroup()];
    setSelectedSubGroups(newItems);
    if (editingGroup !== undefined) {
      const newRelatedIds = editingGroup.subGroupIds
        ? [...editingGroup.subGroupIds, newGroup().id]
        : [newGroup().id];
      setEditingGroup({ ...editingGroup, subGroupIds: newRelatedIds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onSave = async () => {
    setSubmitting(true);
    if (editingGroup !== undefined) {
      let toSendGroup = {
        ...editingGroup,
        machineIds: editingGroup?.machineIds?.filter((entry) => entry > 0),
        userIds: editingGroup?.userIds?.filter((entry) => entry > 0),
        subGroupIds: editingGroup?.subGroupIds?.filter((entry) => entry > 0),
      };
      let requestPromise: Promise<GroupRequestStatus>;
      if (editingGroup.id === 0) {
        requestPromise = createGroup(toSendGroup);
      } else {
        requestPromise = updateGroup(toSendGroup);
      }
      const groupStatus = await requestPromise;

      if (groupStatus.status !== SUCCESS) {
        if (groupStatus.errors !== undefined) {
          setErrors(groupStatus.errors);
        }

        let message = "Ha ocurrido un error!!";
        if (groupStatus.detail !== undefined) {
          message = groupStatus.detail;
        }
        errorAlert(message);
      } else {
        setErrors({});
        onSuccess();
      }
    }
    setSubmitting(false);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingGroup({
      ...editingGroup,
      name: e.target.value,
    });
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingGroup({
      ...editingGroup,
      description: e.target.value,
    });
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    setEditingGroup(newGroup());
  };

  useEffect(() => {
    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGroup]);

  useEffect(() => {
    setEditingGroup(initialGroup ? initialGroup : newGroup());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGroup]);

  useEffect(() => {
    setErrors(initialErrors ? initialErrors : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialErrors]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Datos de Grupo:</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <fieldset disabled={disabled}>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>Nombre</CLabel>
                    </CCol>
                    <CCol>
                      <CInput
                        type="text"
                        placeholder="Ej: Nombre del grupo"
                        value={editingGroup?.name}
                        onChange={onNameChange}
                      ></CInput>
                      <FieldErrors
                        errors={getFieldErrors("name", errors) as string[]}
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>Descripción</CLabel>
                    </CCol>
                    <CCol>
                      <CInput
                        type="text"
                        placeholder="Ej: Descripción"
                        value={editingGroup?.description}
                        onChange={onDescriptionChange}
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors("description", errors) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </fieldset>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Parametros de grupo:</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CNav variant="tabs" fill={true} inCard={true}>
                <CNavItem>
                  <CNavLink
                    href="#"
                    active={activeTab === "machines" ? true : false}
                    onClick={() => {
                      onNavTabClick("machines");
                    }}
                  >
                    Máquinas{" "}
                    <WarningSignError
                      errors={getFieldErrors("machineIds", errors) as string[]}
                    ></WarningSignError>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="#"
                    active={activeTab === "users" ? true : false}
                    onClick={() => {
                      onNavTabClick("users");
                    }}
                  >
                    Usuarios{" "}
                    <WarningSignError
                      errors={getFieldErrors("userIds", errors) as string[]}
                    ></WarningSignError>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="#"
                    active={activeTab === "subGroups" ? true : false}
                    onClick={() => {
                      onNavTabClick("subGroups");
                    }}
                  >
                    SubGrupos{" "}
                    <WarningSignError
                      errors={getFieldErrors("subGroupIds", errors) as string[]}
                    ></WarningSignError>
                  </CNavLink>
                </CNavItem>
              </CNav>
              <CTabContent fade={true}>
                <CTabPane active={activeTab === "machines" ? true : false}>
                  <CContainer fluid={true} className={"pt-3"}>
                    <FieldErrors
                      errors={getFieldErrors("machineIds", errors) as string[]}
                    ></FieldErrors>
                    {selectedMachines ? (
                      selectedMachines.map((item: Machine, ix) => (
                        <CRow key={ix}>
                          <CCol>
                            <GroupSetupMachineItem
                              disabled={disabled}
                              value={item}
                              onDelete={(item: Machine) =>
                                onMachineItemDeleteClick(ix, item)
                              }
                              onChange={(item: Machine | null) =>
                                onMachineItemChange(ix, item)
                              }
                              errors={errors}
                            ></GroupSetupMachineItem>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <></>
                    )}
                    {!disabled ? (
                      <CRow>
                        <CCol>
                          <CButton
                            className="btn btn-success"
                            onClick={onMachineAddItemClick}
                          >
                            Agregar Ítem
                          </CButton>
                        </CCol>
                      </CRow>
                    ) : (
                      <></>
                    )}
                  </CContainer>
                </CTabPane>
                <CTabPane active={activeTab === "users" ? true : false}>
                  <CContainer fluid={true} className={"pt-3"}>
                    <FieldErrors
                      errors={getFieldErrors("userIds", errors) as string[]}
                    ></FieldErrors>
                    {selectedUsers ? (
                      selectedUsers.map((item: User, ix) => (
                        <CRow key={ix}>
                          <CCol>
                            <GroupSetupUserItem
                              value={item}
                              disabled={disabled}
                              onDelete={(item: User) =>
                                onUserItemDeleteClick(ix, item)
                              }
                              onChange={(item: User | null) =>
                                onUserItemChange(ix, item)
                              }
                              errors={errors}
                            ></GroupSetupUserItem>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <></>
                    )}
                    {!disabled ? (
                      <CRow>
                        <CCol>
                          <CButton
                            className="btn btn-success"
                            onClick={onUserAddItemClick}
                          >
                            Agregar Ítem
                          </CButton>
                        </CCol>
                      </CRow>
                    ) : (
                      <></>
                    )}
                  </CContainer>
                </CTabPane>
                <CTabPane active={activeTab === "subGroups" ? true : false}>
                  <CContainer fluid={true} className={"pt-3"}>
                    <FieldErrors
                      errors={getFieldErrors("subGroupIds", errors) as string[]}
                    ></FieldErrors>
                    {selectedSubGroups ? (
                      selectedSubGroups.map((item: Group, ix) => (
                        <CRow key={ix}>
                          <CCol>
                            <GroupSetupSubGroupItem
                              value={item}
                              disabled={disabled}
                              onDelete={(item: Group) =>
                                onSubGroupItemDeleteClick(ix, item)
                              }
                              onChange={(item: Group | null) =>
                                onSubGroupItemChange(ix, item)
                              }
                              errors={errors}
                            ></GroupSetupSubGroupItem>
                          </CCol>
                        </CRow>
                      ))
                    ) : (
                      <></>
                    )}
                    {!disabled ? (
                      <CRow>
                        <CCol>
                          <CButton
                            className="btn btn-success"
                            onClick={onSubGroupAddItemClick}
                          >
                            Agregar Ítem
                          </CButton>
                        </CCol>
                      </CRow>
                    ) : (
                      <></>
                    )}
                  </CContainer>
                </CTabPane>
              </CTabContent>
            </CCardBody>
            <CCardFooter>
              <CFormGroup className="float-right">
                <CButton type="button" color="secondary" onClick={onClose}>
                  Atrás
                </CButton>
                {!disabled ? (
                  <CButton type="submit" color="primary" onClick={onSave}>
                    {submitting ? (
                      <Spinner
                        animation="grow"
                        style={{
                          height: "17px",
                          width: "17px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {submitting ? "Guardando..." : "Guardar Todos los cambios"}
                  </CButton>
                ) : (
                  <></>
                )}
              </CFormGroup>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default GroupSetup;
