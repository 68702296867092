import { CCol, CContainer, CRow } from "@coreui/react";

const Error403 = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center text-dark">
          <CCol md={6}>
            <span className="clearfix">
              <h1 className="float-start display-3 me-4">403</h1>
              <h4 className="pt-3">Acceso denegado!</h4>
              <p className="text-medium-emphasis float-start">
                No tienes permisos para ver esta página.
              </p>
            </span>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Error403;
