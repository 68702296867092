import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CLabel,
  CInputGroup,
  CInputGroupText,
  CButton,
} from "@coreui/react";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getMachineMeasurements } from "../../api/machine-measurement";
import { getMachine } from "../../api/machine";
import Machine, {
  newMachine,
  MACHINE_STATUS_INACTIVE_LABEL,
  MACHINE_STATUS_ACTIVE_LABEL,
  MACHINE_STATUS_ACTIVE,
} from "../../models/machine";
import MachineMeasurement from "../../models/machine-measurement";
import { getRoutePath } from "../../routes";
import { SUCCESS, UNAUTHORIZED } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import { dateToRFC3339 } from "../utils/datetime";

const ITEMS_PER_PAGE = 50;

const MachineDetail = () => {
  const { id } = useParams<{ id: string }>();
  const fields = [
    { key: "timestamp", _classes: ["text-center"], label: "Hora" },
    { key: "cashInput", _classes: ["text-center"], label: "Ingresos" },
    { key: "cashOutput", _classes: ["text-center"], label: "Egresos" },
    { key: "profit", _classes: ["text-center"], label: "Ganancia" },
  ];

  const [loading, setLoading] = useState(true);
  const [measurements, setMeasurements] = useState<MachineMeasurement[]>([]);
  const [machine, setMachine] = useState<Machine | undefined>(newMachine());
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [startTimeField, setStartTimeField] = useState<Date | undefined>(
    undefined
  );
  const [endTimeField, setEndTimeField] = useState<Date | undefined>(undefined);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }
    let startTimeParam = urlParams.get("start_time")
      ? urlParams.get("start_time")
      : "";
    let endTimeParam = urlParams.get("end_time")
      ? urlParams.get("end_time")
      : "";

    return {
      startTimeParam: startTimeParam,
      endTimeParam: endTimeParam,
      page: page,
    };
  };

  const getInitialValues = async () => {
    setLoading(true);
    const machinePromise = fetchMachine(Number(id));
    const measurementsPromise = fetchMeasurements(Number(id));

    await machinePromise;
    await measurementsPromise;
    setLoading(false);
  };

  const fetchMachine = async (id: number) => {
    const machineStatus = await getMachine(id);
    if (machineStatus.status === SUCCESS) {
      if (machineStatus.data !== undefined) {
        setMachine(machineStatus.data);
      }
    } else if (machineStatus.status === UNAUTHORIZED) {
      history.push(getRoutePath("unauthorized"));
    } else {
      const message = machineStatus.detail
        ? machineStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const fetchMeasurements = async (id: number) => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const startTime = urlParams.startTimeParam
      ? urlParams.startTimeParam
      : undefined;
    const endTime = urlParams.endTimeParam ? urlParams.endTimeParam : undefined;
    const measurementsStatus = await getMachineMeasurements(
      limit,
      offset,
      id,
      startTime,
      endTime
    );

    if (measurementsStatus.status === SUCCESS) {
      if (measurementsStatus.data !== undefined) {
        const count = measurementsStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setMeasurements(measurementsStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = measurementsStatus.detail
        ? measurementsStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
  };

  const onStartTimeChange = async (value: string | moment.Moment) => {
    if (
      (value as moment.Moment) !== undefined &&
      moment(value, true).isValid()
    ) {
      const startDate = (value as moment.Moment).toDate();
      setStartTimeField(startDate);
    }
  };

  const onEndTimeChange = async (value: string | moment.Moment) => {
    if (
      (value as moment.Moment) !== undefined &&
      moment(value, true).isValid()
    ) {
      const startDate = (value as moment.Moment).toDate();
      setEndTimeField(startDate);
    }
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    let previousParams = getURLParams();
    urlParams.set(
      "start_time",
      previousParams.startTimeParam ? previousParams.startTimeParam : ""
    );
    urlParams.set(
      "end_time",
      previousParams.endTimeParam ? previousParams.endTimeParam : ""
    );
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onFilter = () => {
    setLoading(true);

    let urlParams = new URLSearchParams(history.location.search);

    if (startTimeField !== undefined) {
      urlParams.set("start_time", dateToRFC3339(startTimeField));
    } else {
      urlParams.set("start_time", "");
    }
    if (endTimeField !== undefined) {
      urlParams.set("end_time", dateToRFC3339(endTimeField));
    } else {
      urlParams.set("end_time", "");
    }
    urlParams.set("page", "1");
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const initialForFilterInputs = () => {
    const urlParams = getURLParams();
    if (urlParams.startTimeParam !== "" && urlParams.startTimeParam !== null) {
      setStartTimeField(new Date(urlParams.startTimeParam));
    }
    if (urlParams.endTimeParam !== "" && urlParams.endTimeParam !== null) {
      setEndTimeField(new Date(urlParams.endTimeParam));
    }
  };

  useEffect(() => {
    getInitialValues();
    initialForFilterInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (
        location.pathname !== getRoutePath("machineDetail").replace(":id", id)
      ) {
        return;
      }
      fetchMeasurements(Number(id));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Detalles de Máquina: {machine?.name}</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="6">Nombre: {machine?.name}</CCol>
                <CCol lg="6">
                  Identificador unico:{" "}
                  {machine?.deviceIdentifier ? machine.deviceIdentifier : "-"}
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  Estado:{" "}
                  {machine?.status === MACHINE_STATUS_ACTIVE
                    ? MACHINE_STATUS_ACTIVE_LABEL
                    : MACHINE_STATUS_INACTIVE_LABEL}
                </CCol>
                <CCol lg="6">
                  Visto el: {machine?.lastSeen ? machine.lastSeen : "-"}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Valores recibidos:</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-calendar mr-2"></i> Fecha de inicio
                    </CInputGroupText>
                    <Datetime
                      className="col-md-9 pl-0"
                      value={startTimeField}
                      onChange={onStartTimeChange}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      closeOnSelect={true}
                    />
                  </CInputGroup>
                </CCol>
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-calendar mr-2"></i> Fecha de fin
                    </CInputGroupText>
                    <Datetime
                      className="col-md-9 pl-0"
                      value={endTimeField}
                      onChange={onEndTimeChange}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      closeOnSelect={true}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onFilter}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={measurements}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    timestamp: (item: MachineMeasurement) => {
                      const timestamp =
                        item.timestamp !== undefined && item.timestamp !== null
                          ? new Date(item.timestamp).toLocaleString("es-PY")
                          : "-";
                      return <td className="text-center">{timestamp}</td>;
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default MachineDetail;
